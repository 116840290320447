import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const StorePage = ({ location }) => (
  <Layout location={location} title="Store">
    <SEO title="Store" />
    <section id="store-page" className="page">
      <div className="container text-white">
        <div className="content bg-black p-5">
          <p>
            Our new fundraising store will be live in 2019! Please check back
            soon or contact us to find out more information.{" "}
            <a
              href="mailto:fundraising@earthseeds.org"
              className="text-lime-green"
            >
              SUBSCRIBE HERE
            </a>{" "}
            to receive our Store Opening Announcement.
          </p>
          <p>
            Our new fundraising merchandise includes Earth Fine Art Canvas
            Gallery Wraps with authentic whole Earth photography from satellite
            cameras and the commonly seen NASA Blue Marble images. You will also
            find some amazing gifts for everyone with Planet Earth imprinted on
            them. Stay tuned for planet Earth wall hangings, Earth apparel,
            Earth tote bags, stationary, beach towels, yoga mats and more! Of
            course, Earth Flags and the Astronaut’s Globe will be available
            soon.
          </p>
        </div>
      </div>
    </section>
  </Layout>
)

export default StorePage
